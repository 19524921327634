import React from 'react';
import SEO from "../components/common/layout/seo"
import PageLayout from "../components/common/layout/pageLayout";

const data = [
  {
    feature: "Serverless Service",
    desc: "Serverless architectures offer greater scalability, more flexibility with reduced costs"
  }, {
    feature: "Notes Sharing",
    desc: "Now easier to create Notes, distribute Notes, make changes all at one place"
  }, {
    feature: "Videos",
    desc: "Private encrypted Videos also supports videos from public locations like Youtube, Vimeo etc"
  }, {
    feature: "Quizzes",
    desc: "Create a quiz, add or edit questions, set time limits, generate reports"
  }, {
    feature: "Live Classes",
    desc: "Can host live classes for more number of Students(~10,000), Student live queries with hand raise, live whiteboard and live chat for increased Student Participation"
  }, {
    feature: "Discussion Forums",
    desc: "Each Course has different discussion space where discussions can be pinned, created, commented and voted"
  }, {
    feature: "Security - No data Misuse",
    desc: "Complete date privacy, no sharing with any third party. Separate storage spaces gives absolutely no shared resources"
  }, {
    feature: "Usability - Use on Any Screen",
    desc: "Entire app can be used on any screen size, any device, any browser"
  },
];
const Features = () => {
  return (
    <PageLayout>
      <SEO title="Features" />
      <h1>Features</h1>
      <ul>
        {data.map(el => (<li key={el.feature}>
          <h4>{el.feature}</h4>
          <p>{el.desc}</p>
        </li>))}
      </ul>
    </PageLayout>
  );
}

export default Features;
